import { Card, CardContent, Grid, IconButton, Typography } from "@mui/material";
import colors from "../themes/colors";
import { useState } from "react";
import ReactCardFlip from "react-card-flip";
import AnimatedChar from "./AnimatedChar";
import {
  FlippingCard,
  FlippingCardBack,
  FlippingCardFront,
} from "react-ui-cards";
import { motion } from "framer-motion";

function ServiceCard({ serviceData }: { serviceData: any }) {
  const [isFlipped, setIsFlipped] = useState<boolean>(false);
  const handleFlip = (e: any) => {
    e.preventDefault();
    setIsFlipped(!isFlipped);
  };

  return (
    <div style={{ marginTop: 30 }}>
      <FlippingCard className="cardflip">
        <FlippingCardBack>
          <div>
            <Card
              variant="outlined"
              sx={{
                backgroundColor: colors.glass,
                borderRadius: 4,
                borderWidth: 1,
                borderColor: colors.white,
                height: 440,
                width: "100%",
              }}
            >
              <CardContent>
                <Grid
                  pt={2}
                  container
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Grid item>
                    <Typography
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical",
                        // padding: 10,
                        width: "100%",
                        color: colors.yellow,
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                      variant="body2"
                    >
                      {serviceData.title}
                    </Typography>

                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "16",
                        WebkitBoxOrient: "vertical",
                        width: "100%",
                        color: colors.white,
                        marginTop: 2,
                      }}
                      dangerouslySetInnerHTML={{
                        __html: serviceData.sub_description,
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
        </FlippingCardBack>
        <FlippingCardFront>
          <Card
            variant="outlined"
            sx={{
              backgroundColor: colors.glass,
              borderRadius: 4,
              borderWidth: 1,
              borderColor: colors.white,
              height: 440,
            }}
          >
            <CardContent>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: 10,
                }}
              >
                <AnimatedChar url={serviceData.icon_url} />
              </div>
              <Grid
                pt={4}
                pb={4}
                container
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid item>
                  <Typography
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "1",
                      WebkitBoxOrient: "vertical",
                      // padding: 10,
                      width: "100%",
                      color: colors.yellow,
                      fontSize: 20,
                      fontWeight: "bold",
                    }}
                    variant="body2"
                  >
                    {serviceData.title}
                  </Typography>

                  <Typography
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "9",
                      WebkitBoxOrient: "vertical",
                      width: "100%",
                      color: colors.white,
                      marginTop: 2,
                    }}
                    variant="body2"
                  >
                    {serviceData.description}
                  </Typography>

                  {/* <p
                    style={{
                      color: colors.white,
                      fontSize: 16,
                      paddingTop: 10,
                      height: 205,
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {serviceData.description}
                  </p> */}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </FlippingCardFront>
      </FlippingCard>
    </div>
  );
}

export default ServiceCard;
