import {
  Grid,
  Avatar,
  TextField,
  Button,
  ButtonGroup,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  BlogCollection,
  BlogColRefWithOrder,
  db,
} from "../../database/Firebase";
import { doc, getDoc, getDocs } from "firebase/firestore/lite";
import { useEffect, useState } from "react";
import ColorLine from "../../components/ColorLine";
import DashboardSlide from "../../components/DashboardSlide";
import Highlight2Component from "../../components/Highlight2Component";
import { useStyles } from "./BlogStyle";
import colors from "../../themes/colors";
import { BlogHomePageCard } from "../../components/BlogCard";
import SendIcon from "@mui/icons-material/Send";
import ScrollAnimation from "react-animate-on-scroll";
import { LocalStorage } from "../../cache/localStorage";
import { CompanyKey } from "../../constant";
import {
  TwitterIcon,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterShareButton,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: colors.green,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: colors.green,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: colors.green,
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderWidth: 1,
    },
    "&:hover fieldset": {
      borderColor: colors.green,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.green,
    },
  },
});

function BlogDetail() {
  const classes = useStyles();
  let location = useLocation();
  const [BlogList, setBlogList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [blogDetails, setBlogDetails] = useState<any>({});

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const key = urlParams.get("id");
    if (key) {
      getBlogData(key);
    }
    // eslint-disable-next-line
    getBlog();
  }, []);

  const getBlogData = async (key: string) => {
    console.log("key", key);

    const response = await getDoc(doc(db, BlogCollection, key));
    console.log("response.data())", response.data());

    if (response.data()) {
      setBlogDetails(response.data());
    }
  };

  let getBlog = async () => {
    setIsLoading(true);
    const response = await getDocs(BlogColRefWithOrder);
    let storeBlog: any = [];
    response.forEach((doc) => {
      storeBlog.push({
        ...doc.data(),
        doc_id: doc.id,
      });
    });
    console.log(storeBlog, isLoading);
    setBlogList(storeBlog);
    setIsLoading(false);
  };

  return (
    <>
      {/* <section>
        <DashboardSlide
          title="Kindly read the think like a monk book"
          description=""
          color="green"
          img="blogBg"
        />
      </section>
      <ColorLine /> */}
      <div className="marginContainer">
        <section style={{ marginTop: 40 }}>
          <p className={classes.sectionTitle}>JuJu Blogosphere</p>
          <Grid
            pt={4}
            container
            direction="row"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item sm={12}>
              <Avatar
                variant="square"
                alt="juju Designz"
                src={blogDetails.blog_image}
                sx={{ width: "100%", height: 300, borderRadius: 3 }}
              />
              <div style={{ paddingTop: 30 }}>
                <Stack direction={"row"} justifyContent={"flex-start"}>
                  <div>
                    <Typography
                      variant="body2"
                      // color="text.secondary"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                        // color: "#fba316",
                        fontWeight: "bold",
                        // fontSize: 12,
                      }}
                    >
                      Author : {blogDetails.blog_author}
                    </Typography>
                  </div>
                  <div style={{ marginLeft: 100 }}>
                    <Typography
                      variant="body2"
                      // color="text.secondary"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                        // color: "#fba316",
                        fontWeight: "bold",
                        // fontSize: 12,
                      }}
                    >
                      Date : {blogDetails.blog_date}
                    </Typography>
                  </div>
                </Stack>
              </div>

              <div style={{ paddingTop: 20 }}>
                <p
                  style={{ fontWeight: "bold", fontSize: 30, color: "#57b21d" }}
                >
                  {blogDetails.blog_title}
                </p>
                <div
                  style={{ marginLeft: 0, paddingLeft: 0, marginTop: 50 }}
                  dangerouslySetInnerHTML={{
                    __html: blogDetails.blog_description,
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </section>
        <section>
          <Grid
            container
            pt={4}
            pb={4}
            alignItems={"center"}
            justifyContent={"start"}
            spacing={4}
          >
            <Grid item xs={12} sm={6}>
              <p style={{ fontSize: 15, fontWeight: "bold", paddingTop: 20 }}>
                Share
              </p>
              <Stack direction={"row"} style={{ paddingTop: 10 }}>
                <WhatsappShareButton
                  style={{
                    paddingLeft: 0,
                    marginLeft: 0,
                    textAlign: "left",
                  }}
                  url={window.location.href}
                  title={`${blogDetails.blog_title} - ${blogDetails.blog_note}`}
                >
                  <WhatsappIcon type="button" size={40} round={true} />
                </WhatsappShareButton>
                <TwitterShareButton
                  style={{ marginLeft: 5 }}
                  url={window.location.href}
                  title={`${blogDetails.blog_title} - ${blogDetails.blog_note}`}
                >
                  <TwitterIcon
                    type="button"
                    size={40}
                    round={true}
                  ></TwitterIcon>
                </TwitterShareButton>
                <TelegramShareButton
                  style={{ marginLeft: 5 }}
                  url={window.location.href}
                  title={`${blogDetails.blog_title} - ${blogDetails.blog_note}`}
                >
                  <TelegramIcon
                    type="button"
                    size={40}
                    round={true}
                  ></TelegramIcon>
                </TelegramShareButton>
              </Stack>
              {/* <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Comments"
                multiline
                rows={4}
                sx={{ borderRadius: 20 }}
                // style={{borderRadius:10}}
              />
              <Button
                className={classes.InputSpace}
                size={"large"}
                style={{
                  background:
                    "linear-gradient(90deg, rgba(102,214,30,1) 0%, rgba(87,178,29,1) 53%)",
                  color: colors.white,
                  textTransform: "capitalize",
                  width: "20%",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                Submit
              </Button> */}
              {/* <ButtonGroup
                fullWidth
                variant="contained"
                style={{
                  borderRadius: 20,
                }}
              >
                <CssTextField
                  // value={values.weight}
                  // onChange={handleChange("weight")}
                  fullWidth
                  style={{
                    backgroundColor: colors.white,
                    borderTopLeftRadius: 20,
                    borderBottomLeftRadius: 20,
                  }}
                  label="Comments"
                  multiline
                  rows={3}
                />
                <Button
                  style={{
                    background:
                      "linear-gradient(90deg, rgba(102,214,30,1) 0%, rgba(87,178,29,1) 53%)",
                    borderTopRightRadius: 20,
                    borderBottomRightRadius: 20,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    width: "20%",
                    textTransform: "capitalize",
                    fontSize: 25,
                    padding: 5,
                  }}
                  endIcon={
                    <SendIcon style={{ fontSize: 40, paddingRight: 10 }} />
                  }
                />
              </ButtonGroup> */}
            </Grid>
          </Grid>
          <p className={classes.sectionTitle}>Related Blogs</p>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"flex-start"}
            spacing={4}
            pb={4}
          >
            {BlogList &&
              BlogList.slice(0, 3).map((item, index) => {
                return (
                  <Grid key={index} item sm={4} mt={4}>
                    <BlogHomePageCard BlogData={item} />
                  </Grid>
                );
              })}
          </Grid>
        </section>
      </div>
      <section>
        <div className="marginContainer">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ textAlign: "center" }}
            pt={10}
            pb={10}
          >
            <ScrollAnimation animateIn="fadeIn">
              <Grid
                sx={{
                  display: { xs: "none", lg: "block" },
                }}
                item
                style={{ textAlign: "center" }}
              >
                <a
                  target="_blank"
                  href={
                    LocalStorage.get(CompanyKey)
                      ? LocalStorage.get(CompanyKey).banner_link
                      : ""
                  }
                >
                  <Avatar
                    variant="square"
                    sx={{ width: "100%", height: 200 }}
                    alt="juju Designz salem"
                    src={
                      LocalStorage.get(CompanyKey)
                        ? LocalStorage.get(CompanyKey).adsUrl
                        : ""
                    }
                  />
                </a>
              </Grid>
              <Grid
                sx={{
                  display: { xs: "block", lg: "none" },
                }}
                xs={12}
                item
                style={{ textAlign: "center" }}
              >
                <a
                  target="_blank"
                  href={
                    LocalStorage.get(CompanyKey)
                      ? LocalStorage.get(CompanyKey).banner_link
                      : ""
                  }
                >
                  <Avatar
                    variant="square"
                    sx={{ width: "100%", height: 200 }}
                    alt="juju Designz salem"
                    src={
                      LocalStorage.get(CompanyKey)
                        ? LocalStorage.get(CompanyKey).adsPortraitUrl
                        : ""
                    }
                  />
                </a>
              </Grid>
            </ScrollAnimation>
          </Grid>
        </div>
      </section>
    </>
  );
}

export default BlogDetail;
