import {
  Avatar,
  Button,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Modal,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import colors from "../themes/colors";
import DOMPurify from "dompurify";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { useState } from "react";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { Box } from "@mui/system";
export function PlanCard({
  planData,
  setCurrent,
}: {
  planData: any;
  setCurrent: (data: any) => void;
}) {
  return (
    <>
      <Grid item container direction="column" justifyContent={"space-between"}>
        <Avatar
          alt="juju Designz plan"
          src="https://cdn.logojoy.com/wp-content/uploads/2018/05/30162655/6_big7-768x591.png"
          sx={{ width: 100, height: 100 }}
        />
        <p
          style={{
            fontSize: 18,
            fontWeight: "bold",
            paddingTop: 10,
            paddingBottom: 20,
          }}
        >
          {planData.title}
        </p>
        <p style={{ height: 250 }}>{planData.description}</p>
        <Button
          variant="contained"
          onClick={() => setCurrent(planData)}
          style={{ backgroundColor: colors.dark_green }}
        >
          View Plan
        </Button>
      </Grid>
    </>
  );
}

export function PlanGradientCard({
  currentDes,
  planList,
}: {
  currentDes: string;
  planList: any;
}) {
  const currentDesContent = DOMPurify.sanitize(currentDes);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [planImage, setPlanImage] = useState("");
  const [title, setTitle] = useState("");

  const handleClick = (planUrl: string, title: string) => {
    setTitle(title);
    setPlanImage(planUrl);
    setOpen(true);
  };
  return (
    <>
      <Paper elevation={10} style={{ borderRadius: 10 }}>
        <div
          style={{
            height: 50,
            padding: 15,
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
            backgroundImage: `linear-gradient(to right,${colors.dark_green}, ${colors.dark_yellow})`,
          }}
        >
          <p style={{ color: colors.white, fontWeight: "bold" }}>
            {currentDes}
          </p>
        </div>
        <List>
          {planList.map((item: any, index: number) => {
            return (
              <ListItemButton
                key={index}
                onClick={() => handleClick(item.planUrl, item.title)}
              >
                <ListItemIcon>
                  <AddCircleOutlineOutlinedIcon
                    style={{ color: colors.dark_green, fontSize: 20 }}
                  />
                </ListItemIcon>
                <ListItemText primary={item.title}></ListItemText>
              </ListItemButton>
            );
          })}
        </List>
      </Paper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid mt={1} container justifyContent={"center"} alignItems={"center"}>
          <Grid mt={1} xs={10} item sm={6}>
            <Paper>
              <Box p={3}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    component="h2"
                  >
                    {title}
                  </Typography>
                  <IconButton onClick={handleClose}>
                    <HighlightOffOutlinedIcon />
                  </IconButton>
                </Stack>

                <Grid item style={{ textAlign: "center" }}>
                  <a href={planImage} download>
                    <Avatar
                      variant="square"
                      sx={{ width: "100%", height: "80vh" }}
                      alt="juju Designz salem"
                      src={planImage}
                    />
                  </a>
                </Grid>

                {/* <p style={{ paddingTop: 10 }}>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
                  accusamus impedit amet, consectetur laudantium nemo unde
                  distinctio esse! Consectetur nam quo animi ea ratione
                  accusantium beatae pariatur voluptate illum incidunt.
                </p> */}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}
