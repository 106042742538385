import { Grid } from "@mui/material";
import colors from "../themes/colors";

export default function ColorLine() {
  return (
    <Grid container style={{ width: "100%" }}>
      <Grid
        item
        xs={3}
        sm={3}
        style={{ height: 5, backgroundColor: colors.yellow }}
      />
      <Grid
        item
        xs={3}
        sm={3}
        style={{ height: 5, backgroundColor: colors.dark_yellow }}
      />
      <Grid
        item
        xs={3}
        sm={3}
        style={{ height: 5, backgroundColor: colors.green }}
      />
      <Grid
        item
        xs={3}
        sm={3}
        style={{ height: 5, backgroundColor: colors.dark_green }}
      />
    </Grid>
  );
}
