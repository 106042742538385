import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActionArea,
  CardActions,
  Button,
  Skeleton,
  Rating,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import DOMPurify from "dompurify";
import colors from "../themes/colors";
import { UserCard, NewsHeaderCard } from "react-ui-cards";
import { motion } from "framer-motion";
import { LocalStorage } from "../cache/localStorage";

export function ProductCard({
  Product,
  withSize,
}: {
  Product: any;
  withSize?: number;
}) {
  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const blog_description = Product.blog_description;
  const blog_descriptionHTML = DOMPurify.sanitize(blog_description);
  const blogParamData = {
    ...Product,
    // blog_description: blog_descriptionHTML,
  };

  return (
    // <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
    <Card
      variant="outlined"
      sx={{ maxWidth: "100%", marginTop: 2, borderWidth: 0, borderRadius: 2 }}
      onClick={() => {
        LocalStorage.store("Product", Product);
        console.log("ProductProductProduct", Product.id);

        // setSearchParams({ id: Product.id });

        console.log("searchParams", searchParams);

        // navigate("/Gift-Store-Details");

        navigate({
          pathname: "/Gift-Store-Details",
          search: `?id=${Product.doc_id}`,
        });
      }}
    >
      <CardActionArea>
        <CardMedia
          className="blogcardEffect"
          component="img"
          height="140"
          image={Product.url}
          alt="juju Designz product"
        />

        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
              // padding: 10,
              width: "100%",
            }}
          >
            {Product.name}
          </Typography>
          <Typography
            gutterBottom
            variant="subtitle1"
            component="div"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              // padding: 10,
              width: "100%",
            }}
          >
            {Product.short_note}
          </Typography>
          <Rating
            name="read-only"
            size="small"
            value={Product.rating}
            readOnly
          />
          <Typography
            style={{ fontWeight: "bold", fontSize: 18 }}
            gutterBottom
            variant="subtitle1"
            component="div"
          >
            ₹ {Product.price}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    // </motion.div>
  );
}
