import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActionArea,
  CardActions,
  Button,
  Skeleton,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import colors from "../themes/colors";
import { UserCard, NewsHeaderCard } from "react-ui-cards";
import { motion } from "framer-motion";

export function BlogCard({
  BlogData,
  withSize,
}: {
  BlogData: any;
  withSize?: number;
}) {
  let navigate = useNavigate();
  const blog_description = BlogData.blog_description;
  const blog_descriptionHTML = DOMPurify.sanitize(blog_description);
  const blogParamData = {
    ...BlogData,
    blog_description: blog_descriptionHTML,
  };

  return (
    <div>
      <Card
        variant="outlined"
        sx={{ maxWidth: withSize ? withSize : 400, borderWidth: 0 }}
      >
        <CardActionArea
          onClick={() => {
            if (BlogData) {
              navigate("/Blog/Blog-details", { state: blogParamData });
            }
          }}
        >
          <CardMedia
            component="img"
            height="auto"
            image={BlogData.blog_image}
            alt="juju Designz blog"
            style={{ borderRadius: 20 }}
          />
          <CardContent>
            <Stack>
              <Typography gutterBottom variant="h5" component="div">
                {BlogData.blog_title}
              </Typography>
              <Typography gutterBottom variant="h5" component="div">
                {BlogData.blog_title}
              </Typography>
            </Stack>
            <Typography gutterBottom variant="h5" component="div">
              {BlogData.blog_title}
            </Typography>
            <div
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                height: 40,
              }}
              dangerouslySetInnerHTML={{ __html: blog_descriptionHTML }}
            />
          </CardContent>
        </CardActionArea>
        {/* <CardActions>
          <Button size="small">Share</Button>
          <Button size="small">Learn More</Button>
        </CardActions> */}
      </Card>
    </div>
  );
}

export function BlogHomePageCard({
  BlogData,
  withSize,
}: {
  BlogData: any;
  withSize?: number;
}) {
  let navigate = useNavigate();
  const blog_description = BlogData.blog_description;
  const blog_descriptionHTML = DOMPurify.sanitize(blog_description);
  const blogParamData = {
    ...BlogData,
    blog_description: blog_descriptionHTML,
  };

  return (
    <motion.div whileHover={{ scale: 0.9 }} whileTap={{ scale: 0.9 }}>
      <Card
        variant="outlined"
        sx={{ maxWidth: "100%", borderWidth: 0, borderRadius: 2 }}
        onClick={() => {
          if (BlogData) {
            // navigate("/Blog/Blog-details", { state: blogParamData });
            navigate({
              pathname: "/Blog/Blog-details",
              search: `?id=${BlogData.doc_id}`,
            });
          }
        }}
      >
        <CardActionArea>
          {BlogData.blog_image ? (
            <CardMedia
              className="blogcardEffect"
              component="img"
              height="140"
              image={BlogData.blog_image}
              alt="juju Designz blog"
            />
          ) : (
            <Skeleton variant="rectangular" width={"100%"} height={140} />
          )}

          <CardContent>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <div>
                <Typography
                  variant="body2"
                  // color="text.secondary"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%",
                    fontStyle: "italic",
                  }}
                >
                  Author: {BlogData.blog_author}
                </Typography>
              </div>
              <div>
                <Typography
                  variant="body2"
                  // color="text.secondary"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%",
                    fontStyle: "italic",
                  }}
                >
                  {BlogData.blog_date}
                </Typography>
              </div>
            </Stack>
            <Typography
              noWrap
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "75vw",
                marginTop: 5,
              }}
              gutterBottom
              variant="h5"
              component="div"
            >
              {BlogData.blog_title}
            </Typography>
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "4",
                WebkitBoxOrient: "vertical",
              }}
              variant="body2"
              color="text.secondary"
            >
              {BlogData.blog_note}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </motion.div>
  );
}
