import { Container, Grid, Button, Stack } from "@mui/material";
import { getDocs } from "firebase/firestore/lite";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LocalStorage } from "../cache/localStorage";
import { CompanyKey } from "../constant";
import { CompanyColRef } from "../database/Firebase";
import { useFooterStyles } from "../styles/FooterStyle";
import colors from "../themes/colors";
import ColorLine from "./ColorLine";
const footerBg = require("../assets/JuJu_Logo-03.png");

export default function FooterComponent() {
  const classes = useFooterStyles();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [CompanyList, setCompanyList] = useState({
    aboutCompany: "",
    adsUrl: "",
    companyAdsDescription: "",
    companyAdsName: "",
    companyFounder: "",
    companyName: "",
    contact: "",
    email: "",
    fabUrl: "",
    headline: "",
    planUrl: "",
    seo: "",
  });

  useEffect(() => {
    // console.log('hellloooooo');
    // eslint-disable-next-line
    // getCompany();
  });

  // let getCompany = async () => {
  //   if (!CompanyList.contact) {
  //     setIsLoading(true);
  //     const response = await getDocs(CompanyColRef);
  //     console.log("xxxx", response);

  //     let Company: any = [];
  //     response.forEach((doc) => {
  //       Company.push({
  //         ...doc.data(),
  //         doc_id: doc.id,
  //       });
  //     });
  //     console.log("xxxx", Company, isLoading);
  //     if (Company.length) {
  //       setCompanyList(Company[0]);
  //     }
  //     setIsLoading(false);
  //   }
  // };

  return (
    <div className={classes.footerbg}>
      <section>
        <div className="marginContainer">
          <Grid
            pt={4}
            container
            direction={"row"}
            justifyContent={"space-around"}
          >
            <Grid item xs={12} sm={3} pt={4}>
              <img
                src={require("../assets/JuJu_Logo-06.png")}
                alt="juju Designz logo"
                width={240}
                height={80}
                style={{ marginLeft: -20 }}
              />
              <p
                className={classes.footerSubTitle}
                style={{ width: "80%", fontSize: 14, color: colors.gray_txt }}
              >
                JuJu designz, a young and budding startup fascinated in making
                adorable creative works stepped on Aug 7 of 2018.
              </p>
            </Grid>
            <Grid item xs={12} sm={3} pt={5}>
              <p className={classes.footerTitle}>Services</p>

              <p
                onClick={() => {
                  navigate("/Services");
                }}
                className="footer-btn"
              >
                Brand Development
              </p>

              <p
                onClick={() => {
                  navigate("/Services");
                }}
                className="footer-btn"
              >
                Print & Digital Design
              </p>
              <p
                onClick={() => {
                  navigate("/Services");
                }}
                className="footer-btn"
              >
                Creating & Editing
              </p>
              <p
                onClick={() => {
                  navigate("/Services");
                }}
                className="footer-btn"
              >
                Packaging Designs
              </p>
              <p
                onClick={() => {
                  navigate("/Services");
                }}
                className="footer-btn"
              >
                Social Media Creatives{" "}
              </p>
              <p
                onClick={() => {
                  navigate("/Services");
                }}
                className="footer-btn"
              >
                Web & Interactive Designs{" "}
              </p>
            </Grid>
            <Grid item xs={12} sm={3} pt={5}>
              <p className={classes.footerTitle}>Expand & Learn</p>
              <p
                onClick={() => {
                  navigate("/Plans");
                }}
                className="footer-btn"
              >
                Service Plan
              </p>
              <p
                onClick={() => {
                  navigate("/Blog");
                }}
                className="footer-btn"
              >
                Blogs
              </p>
              <p
                onClick={() => {
                  navigate("/About");
                }}
                className="footer-btn"
              >
                Customers & Testimonials
              </p>
              <p
                onClick={() => {
                  navigate("/About");
                }}
                className="footer-btn"
              >
                Partners
              </p>
            </Grid>
            <Grid item xs={12} sm={3} pt={5}>
              <p className={classes.footerTitle}>Contact</p>
              <Stack direction="row" alignItems={"center"} spacing={2}>
                <div style={{ paddingTop: 15 }}>
                  <img
                    src={require("../assets/RoundIcons/Icons_User.png")}
                    width={25}
                    height={25}
                    alt="juju Designz juju-user"
                  />
                </div>
                <div>
                  <p className={classes.footerSubTitle}>
                    {LocalStorage.get(CompanyKey)
                      ? LocalStorage.get(CompanyKey).contact
                      : "+91-8220010664"}
                  </p>
                </div>
              </Stack>
              <Stack direction="row" alignItems={"center"} spacing={2}>
                <div style={{ paddingTop: 15 }}>
                  <img
                    src={require("../assets/RoundIcons/Icons_User.png")}
                    width={25}
                    height={25}
                    alt="juju Designz juju-user"
                  />
                </div>
                <div>
                  <p
                    className={classes.footerSubTitle}
                    style={{ fontSize: 15, textTransform: "lowercase" }}
                  >
                    {LocalStorage.get(CompanyKey)
                      ? LocalStorage.get(CompanyKey).email
                      : "mail2jujudesigns@gmail.com"}
                  </p>
                </div>
              </Stack>

              <p className={classes.footerSubTitle}></p>
              <p
                className={classes.footerSubTitle}
                style={{ color: colors.gray_txt }}
              >
                <small>&copy; Copyright 2018, JuJu Designz</small>
                <br />
                <small>All Rights Reserved.</small>
              </p>
            </Grid>
          </Grid>

          <Grid
            container
            pt={3}
            // ={2}
            direction={"row"}
            justifyContent={"space-around"}
          >
            <Grid item xs={12} sm={3} pt={5}>
              <p className={classes.footerTitle}>About JuJu</p>
              <p
                onClick={() => {
                  navigate("/About");
                }}
                className="footer-btn"
                // className={classes.footerSubTitle}
              >
                Company
              </p>
              <p
                onClick={() => {
                  navigate("/About");
                }}
                className="footer-btn"
                // className={classes.footerSubTitle}
              >
                For Sponsor
              </p>
              <p
                onClick={() => {
                  navigate("/Contact");
                }}
                className="footer-btn"
                // className={classes.footerSubTitle}
              >
                Contact Us
              </p>
            </Grid>
            <Grid item xs={12} sm={3} pt={5}>
              <p className={classes.footerTitle}>JuJu Gift Store</p>
              <Button
                className="jump"
                onClick={() => navigate("/Gift-Store")}
                style={{
                  background:
                    "linear-gradient(90deg, rgba(102,214,30,1) 0%, rgba(87,178,29,1) 53%)",
                  color: "white",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                  textTransform: "capitalize",
                }}
                size="small"
              >
                Click to shop
              </Button>
              <p
                className={classes.footerSubTitle}
                style={{ width: "80%", fontSize: 14, color: colors.gray_txt,marginBottom:10 }}
              >
                Our invitations and cards are beautifully designed and
                customizable for any event
              </p>
            </Grid>
            <Grid item xs={12} sm={3} pt={5}>
              <p className={classes.footerTitle}>Security & Privacy</p>
              <p
                onClick={() => {
                  navigate("/Terms-and-conditions");
                }}
                className="footer-btn"
              >
                Terms & Services
              </p>
              <p
                onClick={() => {
                  navigate("/Privacy-policy");
                }}
                className="footer-btn"
              >
                Privacy Policy
              </p>
            </Grid>
            <Grid item xs={12} sm={3} pt={5}>
              {/* <div
              style={{
                backgroundImage: `url(${footerBg})`,
                backgroundSize: 270,
                backgroundRepeat: "no-repeat",
                opacity: 0.3,
                width: "100%",
                height: "100%",
                marginTop: 20,
              }}
            /> */}
              <img
                src={require("../assets/JuJu_Logo-03.png")}
                alt="logo"
                style={{
                  opacity: 0.2,
                  width: "100%",
                  height: "100%",
                }}
              />
              {/* <p className={classes.footerTitle}>Security & Privacy</p>
            <p className={classes.footerSubTitle}>Brand Development</p> */}
            </Grid>
          </Grid>
        </div>
      </section>
      <ColorLine />
    </div>
  );
}
