export const SlideImage = {
  dashboardBg:
    "https://firebasestorage.googleapis.com/v0/b/juju-designz---production.appspot.com/o/Asset%2Fjuju-home-min.png?alt=media&token=70a9a30d-ba8e-48e0-bd17-bd5c69381b2c",
  serviceBg:
    "https://firebasestorage.googleapis.com/v0/b/juju-designz---production.appspot.com/o/Asset%2Fjuju-service-min.png?alt=media&token=197029db-f592-43cc-8b66-300b6256a11b",
  planBg:
    "https://firebasestorage.googleapis.com/v0/b/juju-designz---production.appspot.com/o/Asset%2Fjuju-plan-min.png?alt=media&token=9ea1f72f-67ba-439b-bea0-2e62a1fa31b7",
  aboutBg:
    "https://firebasestorage.googleapis.com/v0/b/juju-designz---production.appspot.com/o/Asset%2Fjuju-about-min.png?alt=media&token=8a89792c-6160-4328-b0db-f3a7d219f12b",
  giftBg:
    "https://firebasestorage.googleapis.com/v0/b/juju-designz---production.appspot.com/o/Asset%2Fjuju-gift-min.png?alt=media&token=c345ed82-7c03-405e-aeb6-be9bffc7e49c",
  blogBg:
    "https://firebasestorage.googleapis.com/v0/b/juju-designz---production.appspot.com/o/Asset%2Fjuju-blog-min.png?alt=media&token=a3c88e37-185d-410f-b272-2e693e956e14",
};

export let env = process.env.ENVIRONMENT || "QA";
export let whatWeOfferImg =
  process.env.WHAT_WE_OFFER_IMAGE ||
  "https://firebasestorage.googleapis.com/v0/b/juju-designz.appspot.com/o/Asset%2FWebServices-01.png?alt=media&token=a7cd4bde-3bac-4fc3-8128-d94e588cc6f4";

export let CompanyKey = "Company";
export let HomeKey = "Home";
