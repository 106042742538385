import React, { useState } from "react";
import SignInComponent from "./SignInComponent";
import SignUpComponent from "./SignUpComponent";
import { motion } from "framer-motion";

function Auth() {
  const [isToggle, setIsToggle] = useState(false);

  const shift = () => {
    setIsToggle(!isToggle);
  };

  return (
    <div>
      {isToggle ? (
        <SignInComponent shift={shift} />
      ) : (
        <SignUpComponent shift={shift} />
      )}
    </div>
  );
}

export default Auth;
