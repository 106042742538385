import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ImageViewer from "react-simple-image-viewer";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Button, Skeleton, Typography } from "@mui/material";
import { motion } from "framer-motion";
import colors from "../themes/colors";
import { Link, useNavigate } from "react-router-dom";
import { ProductCard } from "./ProductCard";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function StoreImageGrid({ ImageData }: { ImageData?: any[] }) {
  let navigate = useNavigate();
  const [currentImage, setCurrentImage] = React.useState(0);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);
  const [windowDimensions] = React.useState(getWindowDimensions());

  const imageUrl: string[] = [];
  ImageData &&
    ImageData.forEach((item) => {
      imageUrl.push(item.url);
    });

  const openImageViewer = React.useCallback((index) => {
    setCurrentImage(index);
    setPhotoIndex(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      {/* <div className="marginContainer"> */}
      {ImageData && ImageData.length === 0 && (
        <Skeleton variant="rectangular" width={"100%"} height={518} />
      )}
      <ImageList
        cols={windowDimensions.width > 400 ? 3 : 1}
        // rowHeight={164}
        sx={{
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 2,
        }}
      >
        {ImageData &&
          ImageData.map((item, index) => (
            <motion.div whileHover={{ scale: 0.9 }}>
              <ProductCard
                Product={{
                  id: item.id,
                  name: item.name,
                  price: item.price,
                  description: item.description,
                  highlight: item.highlight,
                  delivery: item.delivery,
                  rating: item.rating,
                  url: item.url,
                  doc_id: item.doc_id,
                  short_note: item.short_note,
                }}
              />
            </motion.div>
          ))}

        {isViewerOpen && (
          <Lightbox
            wrapperClassName="heloo"
            reactModalStyle={{ marginLeft: 80 }}
            mainSrc={imageUrl[photoIndex]}
            nextSrc={imageUrl[(photoIndex + 1) % imageUrl.length]}
            prevSrc={
              imageUrl[(photoIndex + imageUrl.length - 1) % imageUrl.length]
            }
            onCloseRequest={() => closeImageViewer()}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + imageUrl.length - 1) % imageUrl.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % imageUrl.length)
            }
          />
        )}
        {/* {isViewerOpen && (
          <ImageViewer
            backgroundStyle={{
              width: "90%",
              height: "100%",
              textAlign: "center",
              display: "flex",
              margin: "6%",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              // marginLeft: "13%",
              marginTop: windowDimensions.width < 500 ? "10%" : "0%",
            }}
            src={imageUrl}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )} */}
      </ImageList>
      {/* </div> */}
    </>
  );
}
