import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import colors from "../../themes/colors";
export const useStyles = makeStyles((theme: Theme) => ({
  section: {
    paddingTop: 40,
    paddingBottom: 20,
  },
  sectionTitle: {
    fontSize: 35,
    fontWeight: "bold",
    textAlign: "center",
    paddingTop: 30,
    paddingBottom: 30,
    background: "-webkit-linear-gradient(45deg, #fba316 40%, #57b21d 50%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  sectionDes: {
    textAlign: "center",
    margin: 2,
    fontSize: 18,
    padding: 15,
  },
  sectionSubTitle: {
    textAlign: "left",
    margin: 2,
    fontSize: 22,
    paddingTop: 10,
    paddingBottom: 10,
    padding: 15,
  },
  boxTitle: {
    color: colors.white,
  },
  boxDes: {
    color: colors.white,
  },
  darkSectionTitle: {
    fontSize: 30,
    textAlign: "center",
    paddingTop: 60,
    paddingBottom: 30,
    color: colors.white,
  },
}));
