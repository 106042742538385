import { Avatar } from "@mui/material";
import { motion } from "framer-motion";
import { useSpring, animated } from "react-spring";

function AnimatedChar({ url }: { url: string }) {
  return (
    <animated.div
      style={useSpring({
        loop: { reverse: true },
        to: { y: -10 },
        from: { y: 10 },
      })}
    >
      <Avatar
        alt="juju Designz service"
        src={url}
        sx={{ width: 130, height: 130 }}
      />
    </animated.div>
    // </motion.div>
  );
}

export default AnimatedChar;
