import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ImageViewer from "react-simple-image-viewer";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Skeleton } from "@mui/material";
import { motion } from "framer-motion";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function WorkImageGrid({ ImageData }: { ImageData?: any[] }) {
  const [currentImage, setCurrentImage] = React.useState(0);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);
  const [windowDimensions] = React.useState(getWindowDimensions());

  const imageUrl: string[] = [];
  ImageData &&
    ImageData.forEach((item) => {
      imageUrl.push(item.url);
    });

  const openImageViewer = React.useCallback((index) => {
    setCurrentImage(index);
    setPhotoIndex(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <div className="marginContainer">
        {ImageData && ImageData.length === 0 && (
          <Skeleton variant="rectangular" width={"100%"} height={518} />
        )}
        <ImageList
          cols={windowDimensions.width > 400 ? 3 : 1}
          // rowHeight={164}
          sx={{ width: "100%", height: "100%" }}
        >
          {ImageData &&
            ImageData.map((item, index) => (
              <motion.div
                whileHover={{ scale: 0.9 }}
                whileTap={{ scale: 0.9 }}
              >
                <ImageListItem
                  className="imageGrid"
                  key={item.id}
                  style={{ margin: 4, height: 300 }}
                >
                  <img
                    style={{
                      borderRadius: 10,
                      height: 300,
                      cursor: "pointer",
                    }}
                    height="300"
                    src={`${item.url}?w=164&h=164&auto=format`}
                    srcSet={`${item.url}?w=300&h=300&auto=format&dpr=2 2x`}
                    alt={item.name}
                    loading="lazy"
                    onClick={() => openImageViewer(index)}
                  />
                  {/* <div className="overlay" onClick={() => openImageViewer(index)}>
                  <div className="text">{item.name}</div>
                </div> */}
                </ImageListItem>
              </motion.div>
            ))}

          {isViewerOpen && (
            <Lightbox
              wrapperClassName="heloo"
              reactModalStyle={{ marginLeft: 80 }}
              mainSrc={imageUrl[photoIndex]}
              nextSrc={imageUrl[(photoIndex + 1) % imageUrl.length]}
              prevSrc={
                imageUrl[(photoIndex + imageUrl.length - 1) % imageUrl.length]
              }
              onCloseRequest={() => closeImageViewer()}
              onMovePrevRequest={() =>
                setPhotoIndex(
                  (photoIndex + imageUrl.length - 1) % imageUrl.length
                )
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % imageUrl.length)
              }
            />
          )}
          {/* {isViewerOpen && (
          <ImageViewer
            backgroundStyle={{
              width: "90%",
              height: "100%",
              textAlign: "center",
              display: "flex",
              margin: "6%",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              // marginLeft: "13%",
              marginTop: windowDimensions.width < 500 ? "10%" : "0%",
            }}
            src={imageUrl}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )} */}
        </ImageList>
      </div>
    </>
  );
}
