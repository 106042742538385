import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import RouterComponent from "../routes/routes";
import colors from "../themes/colors";
import { ButtonGroup, Collapse, Stack, Button, Grid } from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MenuIcon from "@mui/icons-material/Menu";
// import { Scrollbars } from "react-custom-scrollbars";
import { Link, useNavigate } from "react-router-dom";
import { ClickAwayListener, Popover } from "@mui/material";
import { styled } from "@mui/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from "react-floating-button-menu";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Switch from "@mui/material/Switch";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { Theme } from "@mui/material";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useTransition, animated, config } from "react-spring";
import ScrollAnimation from "react-animate-on-scroll";
import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";
import ScrollToTop from "../utils/ScrollToTop";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { getDocs } from "firebase/firestore/lite";
import { CompanyColRef } from "../database/Firebase";
const AnimatedCursor = require("react-animated-cursor");
const drawerWidth = 80;

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    TransitionComponent={Fade}
    TransitionProps={{ timeout: 600 }}
    placement="top-start"
    style={{ opacity: 0.3 }}
  />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    fontSize: 15,
    background:
      "linear-gradient(90deg, rgba(251,163,22,1) 21%, rgba(102,214,30,1) 84%)",
    padding: 10,
    width: 200,
    opacity: 0.3,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: colors.dark_yellow,
  },
});

function NavigationComponent(props: any) {
  const { window } = props;
  let location = useLocation();
  let navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState<boolean>(false);
  const [social, setSocialHidden] = React.useState<boolean>(true);
  const [open, setOpen] = React.useState<boolean>(false);
  const [active, isActive] = React.useState<string>("Home");
  const [direction, setDirection] = React.useState("up");
  const [hidden, setHidden] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [CompanyList, setCompanyList] = React.useState({
    aboutCompany: "",
    adsUrl: "",
    companyAdsDescription: "",
    companyAdsName: "",
    companyFounder: "",
    companyName: "",
    contact: "",
    email: "",
    fabUrl: "",
    headline: "",
    planUrl: "",
    seo: "",
  });

  const transitions = useTransition(open, {
    // from: { x: 0, y: 0, opacity: 0 },
    // enter: { x: 100, y: 100, opacity: 1 },
    // leave: { opacity: 0 },
    delay: 200,
    config: config.molasses,
  });

  const handleDirectionChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDirection(event.target.value);
  };

  const handleHiddenChange = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setHidden(event.target.checked);
  };

  const handleClick = () => {
    setOpen(!open);
    setSocialHidden(!social);
    setMobileOpen(false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    setOpen(true);
  };

  const [open1, setOpen1] = React.useState(true);

  const handleTooltipClose = () => {
    setOpen1(false);
  };

  const handleTooltipOpen = () => {
    setOpen1(true);
  };

  React.useEffect(() => {
    //only for navigation active color
    let Path = location.pathname.replace("/", "");
    console.log("PathPathPath", Path);

    if (!Path) {
      isActive(`Home`);
    } else if (Path === "Client-List") {
      isActive("About");
    } else if (Path === "Blog/Blog-details") {
      isActive("Blog");
    } else if (Path === "Gift-Store-Details") {
      isActive("Gift-Store");
    } else {
      isActive(Path);
    }
    const handleScroll = (e: any) => {
      setOpen(false);
      setOpen1(false);
    };
    // eslint-disable-next-line
    getCompany();
    document.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      // This cleans up the event handler when the component unmounts.
      document.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  let getCompany = async () => {
    setIsLoading(true);
    const response = await getDocs(CompanyColRef);

    let Company: any = [];
    response.forEach((doc) => {
      Company.push({
        ...doc.data(),
        doc_id: doc.id,
      });
    });
    if (Company.length) {
      setCompanyList(Company[0]);
    }
    setIsLoading(false);
  };

  const longText = `
Wish you all an
very happy pongal
2022 and Happy new year to all my lovely peoples
`;

  const drawer = (
    <div>
      <Toolbar>
        <IconButton
          size="small"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => navigate("")}
        >
          <Tooltip title="Home" placement="right">
            <img
              src={require("../assets/JuJu_Logo-03.png")}
              width={"55"}
              height={"40"}
              alt="juju Designz logo"
              style={{ marginLeft: -15 }}
            />
          </Tooltip>
        </IconButton>
      </Toolbar>
      <Divider />

      {/* <List style={{ textAlign: "center", marginTop: 60 }}>
        <ListItemIcon onClick={handleClick}>
          <AlignHorizontalLeftIcon
            style={{
              color: open ? colors.dark_yellow : colors.gray_txt,
              fontSize: 40,
            }}
          />
          <p>Menu</p>
        </ListItemIcon>
      </List> */}
      <Stack>
        <div style={{ textAlign: "center", marginTop: 5 }}>
          <IconButton onClick={handleClick}>
            <AlignHorizontalLeftIcon
              className="menu-style"
              style={{
                color: open ? colors.dark_yellow : colors.white,
                fontSize: 40,
              }}
            />
          </IconButton>
        </div>
        <div>
          <p
            style={{
              textAlign: "center",
              color: open ? colors.dark_yellow : colors.white,
            }}
          >
            Menu
          </p>
        </div>
      </Stack>
      <Grid item display={{ xs: "block", lg: "none" }}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {/* mobile */}
          <List style={{ marginTop: 10, backgroundColor: "#3b3d45" }}>
            {[
              { route: "Home", label: "Home" },
              { route: "Services", label: "Our Services" },
              { route: "Work-Done", label: "What've have Done" },
              { route: "Plans", label: "Service Plan" },
              { route: "About", label: "About" },
              { route: "Contact", label: "Contact Us" },
              { route: "Gift-Store", label: "Gift Store" },
              { route: "Blog", label: "Blog" },
            ].map((text, index) => (
              <ListItem
                style={{
                  color: colors.gray_txt,
                  marginTop: 5,
                  textAlign: "left",
                  backgroundColor:
                    text.route === active ? colors.light_gray : "#3b3d45",
                }}
                button
                key={text.route}
                onClick={() => {
                  handleClick();
                  if (text.route === "Home") {
                    navigate("");
                  } else {
                    navigate(`/${text.route}`);
                  }
                }}
              >
                <ListItemText
                  primary={text.label}
                  primaryTypographyProps={{ fontSize: "15px" }}
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </Grid>

      <List
        style={{
          paddingLeft: 20,
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
          // display: "flex",
          // flexDirection: "column",
          position: "absolute",
          bottom: 25,
        }}
      >
        <ListItemIcon
          style={{
            color: colors.gray_txt,
            marginTop: 7,
          }}
        >
          <a href="https://www.facebook.com/design2juju" target="_blank">
            <IconButton className="side-nav-icon">
              <img
                src={require("../assets/RoundIcons/facebook_icon.png")}
                width={20}
                height={20}
                alt="juju Designz juju-facebook"
              />
            </IconButton>
          </a>
        </ListItemIcon>
        <ListItemIcon
          style={{
            color: colors.gray_txt,
            marginTop: 7,
          }}
        >
          <a href="https://www.instagram.com/ju_ju_designz/" target="_blank">
            <IconButton className="side-nav-icon">
              <img
                src={require("../assets/RoundIcons/instagram_icon.png")}
                width={20}
                height={20}
                alt="juju Designz juju-insta"
              />
            </IconButton>
          </a>
        </ListItemIcon>
        <ListItemIcon
          style={{
            color: colors.gray_txt,
            marginTop: 7,
          }}
        >
          <a
            href="https://www.linkedin.com/company/juju-designz"
            target="_blank"
          >
            <IconButton className="side-nav-icon">
              <img
                src={require("../assets/RoundIcons/linkedin_icon.png")}
                width={20}
                height={20}
                alt="juju Designz juju-linked"
              />
            </IconButton>
          </a>
        </ListItemIcon>
        <ListItemIcon
          style={{
            color: colors.gray_txt,
            marginTop: 7,
          }}
        >
          <a href="https://twitter.com/juju_designz" target="_blank">
            <IconButton className="side-nav-icon">
              <img
                src={require("../assets/RoundIcons/twitter_icon.png")}
                width={20}
                height={20}
                alt="juju Designz juju-twitter"
              />
            </IconButton>
          </a>
        </ListItemIcon>

        <ListItemIcon
          style={{
            color: colors.gray_txt,
            marginTop: 7,
            marginLeft: -20,
          }}
        >
          <a target="_blank">
            <IconButton>
              <img
                src={require("../assets/RoundIcons/Icons_User.png")}
                width={50}
                height={50}
                alt="juju Designz user"
              />
            </IconButton>
          </a>
        </ListItemIcon>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
          style={{ backgroundColor: colors.dark_blue }}
        >
          {open ? (
            <motion.div animate={{ x: [-500, 50, 0] }}>
              <Animate
                play
                start={{ opacity: 0 }}
                end={{ opacity: 1 }}
                easeType="cubic-bezier(0.445, 0.05, 0.55, 0.95)"
              >
                <Stack
                  direction={"row"}
                  style={{
                    position: "absolute",
                    top: 80,
                    zIndex: 20,
                    borderRadius: 0,
                    right: 0,
                    left: 0,
                    width: "100%",
                  }}
                  justifyContent="space-between"
                  display={{ xs: "none", lg: "block" }}
                  spacing={4}
                >
                  {/* web */}
                  <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                    style={{
                      width: "100%",
                      backgroundColor: colors.dark_blue,
                      justifyContent: "space-between",
                      boxShadow: "none",
                    }}
                  >
                    {[
                      { route: "Home", label: "Home" },
                      { route: "Services", label: "Our Services" },
                      { route: "Work-Done", label: "What've have Done" },
                      { route: "Plans", label: "Service Plan" },
                      { route: "About", label: "About" },
                      { route: "Contact", label: "Contact Us" },
                      { route: "Gift-Store", label: "Gift Store" },
                      { route: "Blog", label: "Blog" },
                    ].map((list) => {
                      return (
                        <>
                          <Button
                            style={{
                              // color:
                              //   list.route === active
                              //     ? colors.white
                              //     : colors.gray_txt,
                              borderRadius: 0,
                              padding: 20,
                              // fontWeight: "bold",
                              textTransform: "capitalize",
                              fontSize: 16,
                              backgroundColor: colors.dark_blue,
                              opacity: 1,
                              borderWidth: 0,
                            }}
                            onClick={() => {
                              setOpen(false);
                              if (list.route === "Home") {
                                navigate("");
                              } else {
                                navigate(`/${list.route}`);
                              }
                            }}
                          >
                            <p
                              style={{
                                color:
                                  list.route === active
                                    ? colors.dark_yellow
                                    : colors.gray_txt,
                              }}
                            >
                              {list.label}
                            </p>
                          </Button>
                        </>
                      );
                    })}
                    <Button
                      style={{
                        color: colors.dark_yellow,
                        borderRadius: 0,
                        padding: 20,
                        // fontWeight: "bold",
                        textTransform: "capitalize",
                        fontSize: 16,
                        background:
                          "linear-gradient(90deg, rgba(102,214,30,1) 0%, rgba(87,178,29,1) 53%)",
                        opacity: 0.8,
                        borderColor: colors.dark_blue,
                      }}
                    >
                      <HelpOutlineIcon style={{ color: "white" }} />
                    </Button>
                  </ButtonGroup>
                </Stack>
              </Animate>
            </motion.div>
          ) : null}

          <Toolbar sx={{ display: { sm: "none" } }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Welcome
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: "100%",
                borderRight: 0,
              },
            }}
          >
            <div
              style={{
                height: "100%",
                backgroundColor: colors.dark_blue,
              }}
            >
              {drawer}
            </div>
          </Drawer>

          <Drawer
            variant="persistent"
            // PaperProps={{ style: { height: "100vh" } }}
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                borderRight: 0,
              },
            }}
            open
          >
            {/* <div style={{ zIndex: 100, position: "absolute" }}>
              <AnimatedCursor
                innerSize={13}
                outerSize={8}
                color="87, 178, 29"
                outerAlpha={0.2}
                innerScale={0.7}
                outerScale={5}
                clickables={[
                  "a",
                  'input[type="text"]',
                  'input[type="email"]',
                  'input[type="number"]',
                  'input[type="submit"]',
                  'input[type="image"]',
                  "label[for]",
                  "select",
                  "textarea",
                  "button",
                  ".link",
                ]}
              />
            </div> */}
            <div
              style={{
                height: "100%",
                backgroundColor: colors.dark_blue,
              }}
            >
              {drawer}
            </div>
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          {/* <Toolbar /> */}
          <ScrollToTop />
          <RouterComponent />

          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              {open1 && (
                <div
                  style={{
                    position: "fixed",
                    right: 30,
                    bottom: 75,
                    zIndex: 10,
                  }}
                >
                  <img
                    src={CompanyList.fabUrl}
                    width={180}
                    height={180}
                    alt="juju Designz juju Designz popup"
                  />
                </div>
              )}

              <IconButton
                style={{
                  position: "fixed",
                  right: 30,
                  bottom: 25,
                  zIndex: 10,
                }}
                onClick={() => handleTooltipOpen()}
              >
                <img
                  src={require("../../src/assets/Icons_FestPopups.png")}
                  width={50}
                  height={50}
                  alt="juju Designz popup"
                />
              </IconButton>
            </div>
          </ClickAwayListener>
        </Box>
      </Box>
    </>
  );
}

NavigationComponent.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default NavigationComponent;
