import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import colors from "../../themes/colors";
const dashboard = require("../../assets/juju-home.png");

export const useStyles = makeStyles((theme?: Theme) => ({
  section: {
    paddingTop: 40,
    paddingBottom: 40,
  },
  sectionTitle: {
    fontSize: 35,
    fontWeight: "bold",
    textAlign: "center",
    paddingTop: 30,
    paddingBottom: 30,
    background: "-webkit-linear-gradient(45deg, #fba316 40%, #57b21d 50%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  countSec: {
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: 30,
  },
  countMessage: {
    fontSize: 25,
  },
  countNumber: {
    fontSize: 50,
    fontWeight: "bold",
    paddingLeft: 20,
  },
  title: {
    fontSize: 30,
    textAlign: "left",
    paddingTop: 30,
    paddingBottom: 5,
    color: colors.yellow,
  },
  InputSpace: {
    marginTop: 20,
    marginBottom: 20,
    color: colors.white,
  },
  input: {
    "&::placeholder": {
      fontStyle: "italic",
      color: "white",
    },
  },
  headTitle: {
    fontSize: 20,
    textAlign: "left",
    paddingTop: 30,
    paddingBottom: 5,
    paddingLeft: 10,
    color: colors.white,
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  subTitle0: {
    fontSize: 15,
    textAlign: "left",
    paddingTop: 5,
    paddingBottom: 5,
    color: colors.white,
  },
  subTitle: {
    fontSize: 15,
    textAlign: "left",
    paddingTop: 5,
    paddingBottom: 5,
    color: colors.white,
    paddingLeft: 63,
  },
  darkSectionTitle: {
    fontSize: 30,
    textAlign: "center",
    paddingTop: 60,
    paddingBottom: 30,
    color: colors.white,
  },
  bg_image: {
    width: "100%",
    // height: "100%",
    backgroundImage: `url(${dashboard})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    alignItems: "center",
  },
  titleSplit: {
    // textAlign: "center",
    color: colors.white,
    fontSize: 40,
    fontWeight: "bold",
  },
  titleSplit2: {
    // textAlign: "center",
    color: colors.green,
    fontSize: 40,
    fontWeight: "bold",
  },
  description: {
    color: colors.white,
    fontSize: 20,
  },
  fabBtn: {
    position: "absolute",
    right: 60,
    bottom: 40,
  },
}));
