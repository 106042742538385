import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import colors from "../../themes/colors";
const dashboard = require("../../assets/dashboard-image.png");

export const useStyles = makeStyles((theme?: Theme) => ({
  section: {
    paddingTop: 40,
    paddingBottom: 40,
  },
  countSec: {
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: 30,
  },
  countMessage: {
    fontSize: 25,
  },
  countNumber: {
    fontSize: 50,
    fontWeight: "bold",
    paddingLeft: 20,
  },
  title: {
    fontSize: 30,
    textAlign: "left",
    paddingTop: 30,
    paddingBottom: 5,
    color: colors.dark_blue,
  },
  InputSpace: {
    marginTop: 20,
    marginBottom: 20,
  },
  headTitle: {
    fontSize: 20,
    textAlign: "left",
    paddingTop: 30,
    paddingBottom: 5,
  },
  subTitle: {
    fontSize: 15,
    textAlign: "left",
    paddingTop: 10,
    paddingBottom: 5,
  },
  darkSectionTitle: {
    fontSize: 30,
    textAlign: "center",
    paddingTop: 60,
    paddingBottom: 30,
    color: colors.white,
  },
  bg_image: {
    width: "100%",
    height: "100vh",
    backgroundImage: `url(${dashboard})`,
    backgroundSize: "cover",
    position: "relative",
    backgroundRepeat: "no-repeat",
    alignItems: "center",
  },
  titleSplit: {
    // textAlign: "center",
    color: colors.white,
    fontSize: 40,
    fontWeight: "bold",
  },
  titleSplit2: {
    // textAlign: "center",
    color: colors.green,
    fontSize: 40,
    fontWeight: "bold",
  },
  description: {
    color: colors.white,
    fontSize: 20,
  },
  fabBtn: {
    position: "absolute",
    right: 60,
    bottom: 40,
  },
}));
