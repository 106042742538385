// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  doc,
  query,
  orderBy,
} from "firebase/firestore/lite";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
initializeApp(firebaseConfig);
export const db = getFirestore();
export const storage = getStorage();

//collection
export const HomeCollection = process.env.REACT_APP_HOME_COL || "Home";
export const ClientCollection = process.env.REACT_APP_CLIENT_COL || "Client";
export const ServiceCollection = process.env.REACT_APP_SERVICE_COL || "Service";
export const BlogCollection = process.env.REACT_APP_BLOG_COL || "Blog";
export const CompanyCollection = process.env.REACT_APP_COMPANY_COL || "Company";
export const PlanCollection = process.env.REACT_APP_PLAN_COL || "Plan";

export const WorkDoneCollection =
  process.env.REACT_APP_WORK_DONE_COL || "WorkDone";
export const TestimonialCollection =
  process.env.REACT_APP_TESTIMONIAL_COL || "Testimonal";
export const WorkCollection = process.env.REACT_APP_WORK_COL || "Work";
export const ProductCollection = process.env.REACT_APP_WORK_COL || "Product";
export const MessageCollection = process.env.REACT_APP_MESSAGE_COL || "Message";
export const OrderCollection = process.env.REACT_APP_ORDER_COL || "Order";

//Order Routes
export const OrderColRef = collection(db, OrderCollection);

//Message Routes
export const messageColRef = collection(db, MessageCollection);

//User Routes
export const userColRef = collection(db, HomeCollection);
export const userDocRef = doc(db, HomeCollection, "1");

//Client Routes
export const clientColRef = collection(db, ClientCollection);
export const clientColRefWithOrder = query(
  clientColRef,
  orderBy("created_at", "desc")
);

//Service Routes
export const serviceColRef = collection(db, ServiceCollection);
export const ServiceColRefWithOrder = query(
  serviceColRef,
  orderBy("created_at", "desc")
);

//WorkDone Routes
export const WorkDoneColRef = collection(db, WorkDoneCollection);
export const WorkDoneColRefWithOrder = query(
  WorkDoneColRef,
  orderBy("created_at", "desc")
);

//Work Routes
export const WorkColRef = collection(db, WorkCollection);
export const WorkColRefWithOrder = query(
  WorkColRef,
  orderBy("created_at", "desc")
);

//Blog Routes
export const BlogColRef = collection(db, BlogCollection);
export const BlogColRefWithOrder = query(
  BlogColRef,
  orderBy("created_at", "desc")
);

//Company Routes
export const CompanyColRef = collection(db, CompanyCollection);
export const CompanyColRefWithOrder = query(
  CompanyColRef,
  orderBy("created_at", "desc")
);
export const CompanyDocRef = doc(db, CompanyCollection, "1");

//Testimonial Routes
export const TestimonialColRef = collection(db, TestimonialCollection);
export const TestimonialColRefWithOrder = query(
  TestimonialColRef,
  orderBy("created_at", "desc")
);

//Service Routes
export const planColRef = collection(db, PlanCollection);
export const PlanColRefWithOrder = query(
  planColRef,
  orderBy("created_at", "desc")
);

//Product Routes
export const ProductColRef = collection(db, ProductCollection);
export const ProductColRefWithOrder = query(
  ProductColRef,
  orderBy("created_at", "desc")
);
