import {
  Grid,
  Typography,
  Button,
  Modal,
  Box,
  Paper,
  TextField,
  Stack,
  IconButton,
  Snackbar,
  Rating,
  Skeleton,
} from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import React, { useEffect } from "react";
import colors from "../../themes/colors";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Lightbox from "react-image-lightbox";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import Product from "../../components/Product";
import emailjs from "@emailjs/browser";
import {
  TwitterIcon,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterShareButton,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";
import { LocalStorage } from "../../cache/localStorage";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  db,
  OrderColRef,
  ProductCollection,
  ProductColRef,
  ProductColRefWithOrder,
} from "../../database/Firebase";
import { addDoc, doc, getDoc, getDocs } from "firebase/firestore/lite";
import StoreImageGrid from "../../components/StoreImageGrid";
import { async } from "@firebase/util";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function StoreDetailsComponent() {
  let location = useLocation();

  const [open, setOpen] = React.useState(false);
  const [Toast, setToast] = React.useState(false);
  const [viewerOpen, SetViewerOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [ProductList, setProductList] = React.useState<any[]>([]);

  const [selectedProduct, setSelectedProduct] = React.useState<any>({
    id: "",
    name: "",
    category: "",
    price: "",
    description: "",
    rating: "",
    url: "",
    created_at: "",
  });

  const [orderDetails, setOrderDetails] = React.useState<any>({
    name: "",
    email: "",
    street: "",
    pin_code: "",
    state: "",
    city: "",
    phone: "",
    product: {},
  });

  const closeImageViewer = () => {
    SetViewerOpen(false);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const key = urlParams.get("id");
    if (key) {
      getProductData(key);
    }
    // eslint-disable-next-line
    getProduct();
    window.scrollTo(0, 0);
  }, [location.search]);

  const getProductData = async (key: string) => {
    const response = await getDoc(doc(db, ProductCollection, key));
    if (response.data()) {
      setSelectedProduct(response.data());
    }
  };

  let getProduct = async () => {
    setIsLoading(true);
    const response = await getDocs(ProductColRefWithOrder);

    let storeProduct: any = [];
    response.forEach((doc) => {
      storeProduct.push({
        ...doc.data(),
        doc_id: doc.id,
      });
    });
    console.log(storeProduct);
    setProductList(storeProduct);
    setIsLoading(false);
  };

  const handleChange = (event: any) => {
    console.log("event", event.target.value);
    let value = event.target.value;
    let name = event.target.name;

    setOrderDetails((prevalue: any) => {
      return {
        ...prevalue, // Spread Operator
        [name]: value,
      };
    });
  };

  const handleSubmit = async () => {
    try {
      if (orderDetails.email.length >= 3 && orderDetails.phone.length >= 3) {
        await addDoc(OrderColRef, {
          ...orderDetails,
          id: Date.now().toString(36) + Math.random().toString(36).substr(2),
          product: selectedProduct,
          created_at: new Date(),
        });

        // send the message and get a callback with an error or details of the message that was sent
        emailjs
          .send(
            "service_ls4b372",
            "template_n8flgs8",
            {
              to_name: "JuJu Designz - Order Place Successfully",
              from_name: orderDetails.name,
              product_id: selectedProduct.id,
              product: selectedProduct.name,
              price: selectedProduct.price,
              name: orderDetails.name,
              phone: orderDetails.phone,
              door_no: orderDetails.door_no,
              address: orderDetails.street,
              pincode: orderDetails.pin_code,
              state: orderDetails.state,
              city: orderDetails.city,
            },
            "lo6pmm-c6wXF6d1lj"
          )
          .then(
            function (response) {
              console.log("SUCCESS!", response.status, response.text);
            },
            function (error) {
              console.log("FAILED...", error);
            }
          );
        setOrderDetails({
          name: "",
          email: "",
          street: "",
          pin_code: "",
          state: "",
          city: "",
          phone: "",
          product: {},
        });
        setToast(true);
      } else {
        alert("Invalid entry");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <section>
        <div className="marginContainer">
          <Grid
            container
            pt={5}
            pb={10}
            alignItems={"start"}
            justifyContent="space-between"
          >
            <Grid item xs={12} pt={4} sm={4}>
              <Grid container>
                <Grid item sm={12}>
                  <img
                    style={{
                      height: 300,
                      // width: 400,
                      cursor: "pointer",
                    }}
                    onClick={() => SetViewerOpen(true)}
                    // height="300"
                    src={`${selectedProduct.url}?w=300&h=300&auto=format`}
                    srcSet={`${selectedProduct.url}?w=300&h=300&auto=format&dpr=2 2x`}
                    alt={"juju Designz"}
                    loading="lazy"
                  />
                  {viewerOpen && (
                    <Lightbox
                      wrapperClassName="heloo"
                      reactModalStyle={{ marginLeft: 80 }}
                      mainSrc={`${selectedProduct.url}?w=164&h=164&auto=format`}
                      onCloseRequest={() => closeImageViewer()}
                    />
                  )}
                </Grid>
                <Stack>
                  <p
                    style={{ fontSize: 15, fontWeight: "bold", paddingTop: 20 }}
                  >
                    Ratings
                  </p>
                  <Rating
                    name="read-only"
                    value={+selectedProduct.rating}
                    readOnly
                  />
                  <p
                    style={{ fontSize: 15, fontWeight: "bold", paddingTop: 20 }}
                  >
                    Share
                  </p>
                  <Stack direction={"row"} style={{ paddingTop: 10 }}>
                    <WhatsappShareButton
                      style={{
                        paddingLeft: 0,
                        marginLeft: 0,
                        textAlign: "left",
                      }}
                      url={window.location.href}
                      title={`${selectedProduct.name} - Price : ${selectedProduct.price}₹ - ${selectedProduct.description}`}
                    >
                      <WhatsappIcon type="button" size={40} round={true} />
                    </WhatsappShareButton>
                    <TwitterShareButton
                      style={{ marginLeft: 5 }}
                      url={window.location.href}
                      title={`${selectedProduct.name} - Price : ${selectedProduct.price}₹ - ${selectedProduct.description}`}
                    >
                      <TwitterIcon
                        type="button"
                        size={40}
                        round={true}
                      ></TwitterIcon>
                    </TwitterShareButton>
                    <TelegramShareButton
                      style={{ marginLeft: 5 }}
                      url={window.location.href}
                      title={`${selectedProduct.name} - Price : ${selectedProduct.price}₹ - ${selectedProduct.description}`}
                    >
                      <TelegramIcon
                        type="button"
                        size={40}
                        round={true}
                      ></TelegramIcon>
                    </TelegramShareButton>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Grid item xs={12} pt={4} sm={6}>
              <Typography variant="h3">{selectedProduct.name}</Typography>
              <Typography
                pt={2}
                variant="h4"
                style={{ color: colors.dark_green }}
              >
                {selectedProduct.price} ₹
              </Typography>
              {/* <Typography pt={2}>{selectedProduct.description}</Typography> */}
              <div style={{ marginTop: 30 }}>
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography fontWeight={"bold"}>Description</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* <Typography>{selectedProduct.description}</Typography> */}
                    <div
                      style={{ marginLeft: 0, paddingLeft: 10 }}
                      dangerouslySetInnerHTML={{
                        __html: selectedProduct.description,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography fontWeight={"bold"}>Highlights</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      style={{ marginLeft: 0, paddingLeft: 10 }}
                      dangerouslySetInnerHTML={{
                        __html: selectedProduct.highlight,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography fontWeight={"bold"}>Delivery</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      style={{ marginLeft: 0, paddingLeft: 10 }}
                      dangerouslySetInnerHTML={{
                        __html: selectedProduct.delivery,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              </div>
              <Button
                onClick={() => setOpen(true)}
                fullWidth
                size={"large"}
                style={{
                  background:
                    "linear-gradient(90deg, rgba(102,214,30,1) 0%, rgba(87,178,29,1) 53%)",
                  color: colors.white,
                  textTransform: "capitalize",
                  // width: "20%",
                  paddingLeft: 20,
                  paddingRight: 20,
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                Order Now
              </Button>
            </Grid>
          </Grid>
        </div>
      </section>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container alignItems={"center"} justifyContent="center">
          <Grid item xs={11} sm={8}>
            <Paper>
              <Box p={3} mt={4}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    component="h2"
                    fontWeight={"bold"}
                  >
                    Contact Details
                  </Typography>
                  <IconButton onClick={handleClose}>
                    <HighlightOffOutlinedIcon />
                  </IconButton>
                </Stack>

                <Stack
                  pt={3}
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <TextField
                    style={{ marginTop: 20, width: "48%" }}
                    defaultValue={"Jayaprakash P"}
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                    name="name"
                    value={orderDetails.name}
                    onChange={handleChange}
                  />

                  <TextField
                    style={{ marginTop: 20, width: "48%" }}
                    defaultValue={"114"}
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    name="email"
                    value={orderDetails.email}
                    onChange={handleChange}
                  />
                </Stack>

                <TextField
                  style={{ marginTop: 20 }}
                  fullWidth
                  defaultValue={
                    "sri cendrya perumal kovil ottankaradu,alagapuram"
                  }
                  maxRows={4}
                  id="standard-multiline-static"
                  label="Street Address"
                  variant="outlined"
                  name="street"
                  value={orderDetails.street}
                  onChange={handleChange}
                />

                <Stack direction={"row"} justifyContent={"space-between"}>
                  <TextField
                    style={{ marginTop: 20, width: "48%" }}
                    fullWidth
                    defaultValue={"636016"}
                    id="outlined-basic"
                    label="Pin code"
                    variant="outlined"
                    name="pin_code"
                    value={orderDetails.pin_code}
                    onChange={handleChange}
                  />

                  <TextField
                    style={{ marginTop: 20, width: "48%" }}
                    fullWidth
                    defaultValue={"Tamil Nadu"}
                    id="standard-multiline-static"
                    label="State"
                    variant="outlined"
                    name="state"
                    value={orderDetails.state}
                    onChange={handleChange}
                  />
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <TextField
                    style={{ marginTop: 20, width: "48%" }}
                    fullWidth
                    defaultValue={"Salem"}
                    id="standard-multiline-static"
                    label="City"
                    variant="outlined"
                    name="city"
                    value={orderDetails.city}
                    onChange={handleChange}
                  />

                  <TextField
                    style={{ marginTop: 20, width: "48%" }}
                    defaultValue={"9944697696"}
                    id="outlined-basic"
                    label="Phone"
                    variant="outlined"
                    name="phone"
                    value={orderDetails.phone}
                    onChange={handleChange}
                  />
                </Stack>

                <Button
                  onClick={() => {
                    setOpen(false);
                    
                    handleSubmit();
                  }}
                  fullWidth
                  size={"large"}
                  style={{
                    background:
                      "linear-gradient(90deg, rgba(102,214,30,1) 0%, rgba(87,178,29,1) 53%)",
                    color: colors.white,
                    textTransform: "capitalize",
                    // width: "20%",
                    paddingLeft: 20,
                    paddingRight: 20,
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  Place Order
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Toast}
        onClose={() => {
          setToast(false);
        }}
        message="I love snacks"
        key={"12345678"}
      >
        <Alert
          onClose={() => {
            setToast(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          Order placed successfully we reach u soon, Thanks
        </Alert>
      </Snackbar>
      <section>
        <div className="marginContainer">
          <Typography variant="h5" pt={2} fontWeight={"bold"}>
            Related Products
          </Typography>
          <Grid pt={4} direction={"row"} container spacing={3}>
            {/* {ProductList.slice(0,7).map((item) => {
              return (
                <Grid item sm={4}>
                  <Product />
                </Grid>
              );
            })} */}
            {ProductList && ProductList.length === 0 ? (
              <Skeleton variant="rectangular" width={"100%"} height={518} />
            ) : (
              <StoreImageGrid ImageData={ProductList.slice(0, 6)} />
            )}
          </Grid>
        </div>
      </section>
    </>
  );
}

export default StoreDetailsComponent;
