import {
  Card,
  CardContent,
  Grid,
  Rating,
  Avatar,
  Typography,
} from "@mui/material";
import colors from "../themes/colors";
import { motion } from "framer-motion";

function TestimonialCard({ TestimonialData }: { TestimonialData?: any }) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <motion.div whileHover={{ scale: 0.9 }}>
          <Avatar
            style={{
              position: "absolute",
              marginTop: -30,
              marginLeft: 20,
              zIndex: 10,
            }}
            alt="juju Designz testi"
            src={TestimonialData.profileUrl}
            sx={{ width: 50, height: 50 }}
          />
          <Card
            variant="outlined"
            className="bg-blur"
            sx={{
              width: "100%",
              backgroundColor: colors.glass,
              borderRadius: 4,
              borderWidth: 1,
              borderColor: colors.white,
              marginTop: 5,
              height: 300,
              position: "relative",
            }}
          >
            <CardContent>
              <Grid
                // pt={8}
                container
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid item xs={7}>
                  <p
                    style={{
                      color: colors.white,
                      fontSize: 12,
                      fontWeight: "bold",
                    }}
                  >
                    {TestimonialData.name}
                  </p>
                  <p
                    style={{
                      color: colors.white,
                      fontSize: 10,
                      fontStyle: "italic",
                    }}
                  >
                    {TestimonialData.company}
                  </p>
                </Grid>
                <Grid item xs={5} style={{ paddingRight: 20 }}>
                  <Avatar
                    alt="juju Designz testi"
                    src={TestimonialData.companyUrl}
                    sx={{ width: 56, height: 56 }}
                  />
                </Grid>
              </Grid>
              <Grid item pt={2} style={{ height: 160 }}>
                {/* <p
                  style={{
                    color: colors.white,
                    fontSize: 14,
                    height: 165,
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {TestimonialData.description}
                </p> */}

                <Typography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "7",
                    color: colors.white,
                    fontSize: 14,
                    // height: 165,
                    WebkitBoxOrient: "vertical",
                  }}
                  variant="body2"
                  color="text.secondary"
                >
                  {TestimonialData.description}
                </Typography>
              </Grid>
              <Grid item>
                <Rating
                  style={{ paddingTop: 20 }}
                  name="simple-controlled"
                  value={TestimonialData.rating}
                />
              </Grid>
            </CardContent>
          </Card>
        </motion.div>
      </Grid>
    </Grid>
  );
}

export default TestimonialCard;
