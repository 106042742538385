import { Button, Grid } from "@mui/material";
import colors from "../themes/colors";
import ColorLine from "./ColorLine";

function Highlight2Component() {
  return (
    <>
      <section>
        <Grid
          container
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          style={{
            backgroundColor: colors.dark_blue,
            borderRadius: 15,
            paddingBottom: 10,
          }}
        >
          <Grid pt={5} pb={5} item p={2} style={{ textAlign: "center" }}>
            <img
              src={require("../../src/assets/JuJu_Logo-06.png")}
              alt="juju Designz logo"
              width={200}
              height={70}
              style={{ marginLeft: "-10%" }}
            />
            <p
              style={{
                color: colors.white,
                textAlign: "center",
                fontSize: 20,
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              <div>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit ?
              </div>
            </p>
            <Button
              style={{
                backgroundColor: colors.dark_green,
                color: colors.white,
                textTransform: "capitalize",
                fontSize: 17,
                marginTop: 20,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              Start a Project !
            </Button>
          </Grid>
          <ColorLine />
        </Grid>
      </section>
    </>
  );
}

export default Highlight2Component;
