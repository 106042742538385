import React, { useEffect } from "react";
import {
  Grid,
  Button,
  ButtonGroup,
  TextField,
  Avatar,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Stack,
} from "@mui/material";
import ScrollAnimation from "react-animate-on-scroll";
import colors from "../../themes/colors";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

function SectionSlide(props: { serviceData: any; WorkDoneList: any[] }) {
  let navigate = useNavigate();
  let serviceData = props.serviceData.data;
  let serviceIndex = props.serviceData.i;
  let WorkDoneList = props.WorkDoneList;

  // console.log("WorkDoneList", WorkDoneList);

  return (
    <section style={{ backgroundColor: colors.dark_blue }}>
      <Grid
        container
        spacing={2}
        direction={"row"}
        justifyContent="space-around"
        alignItems="stretch"
        pt={5}
        pb={2}
      >
        {/* <Grid
          item
          sm={2}
          alignItems={"stretch"}
          alignSelf={"center"}
          xs={1}
          sx={{ display: { xs: "none", sm: "block" } }}
          p={2}
        >
          <p
            style={{
              transform: "rotate(-90deg)",
              color: "#4A546A",
              fontSize: 30,
              fontWeight: "bold",
              textAlign: "center",
              paddingTop: 20,
              textTransform: "uppercase",
            }}
          >
            {serviceData.title}
          </p>
        </Grid> */}
        <Grid item sm={10} xs={12}>
          <Grid container direction={"row"}>
            <Grid item sm={6} md={6} xs={12}>
              <div>
                <img
                  src={serviceData.service_url}
                  width={"95%"}
                  height={"80%"}
                  alt="juju Designz logo"
                  style={{ borderRadius: 30 }}
                />
              </div>
            </Grid>
            <Grid item sm={6} md={6}>
              <div>
                {/* <p
                  style={{
                    color: "#4A546A",
                    fontSize: 60,
                    fontWeight: "bold",
                  }}
                >
                  {serviceIndex + 1}
                </p> */}
                <p
                  style={{
                    color: "white",
                    fontSize: 25,
                    fontWeight: "bold",
                  }}
                >
                  {serviceData.title}
                </p>
                <p
                  style={{
                    color: "white",
                    width: "100%",
                    marginTop: 10,
                    fontSize: 14,
                    overflow: "hidden",
                    height: 120,

                    // textAlign:'justify'
                  }}
                >
                  {serviceData.description}
                </p>

                <button
                  className="jump"
                  style={{
                    backgroundColor: "#272b36",
                    border: "none",
                    cursor: "pointer",
                    color: "#ffc20f",
                    fontSize: 14,
                    marginTop: 20,
                  }}
                  onClick={() => navigate("/Services")}
                >
                  Read More {">"}
                </button>
                {/* <Button
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  disableElevation
                  variant="text"
                  style={{
                    color: colors.yellow,
                    textTransform: "capitalize",
                    marginTop: 10,
                  }}
                  onClick={() => navigate("/Services")}
                >
                  Read More {">"}
                </Button> */}
              </div>
            </Grid>
          </Grid>
          <p
            style={{
              color: colors.green,
              fontSize: 18,
              textAlign: "left",
              paddingTop: 10,
              paddingBottom: 15,
              fontWeight: "bold",
            }}
          >
            Portfolio
          </p>
          {/* <AliceCarousel
              mouseTracking
              autoPlay={true}
              infinite={true}
              items={items}
              responsive={responsive}
              controlsStrategy="default"
              autoPlayInterval={2000}
            /> */}
          <Stack
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
          >
            {WorkDoneList[0] && WorkDoneList[0].url ? (
              <div>
                <img
                  src={
                    WorkDoneList[0] && WorkDoneList[0].url
                      ? WorkDoneList[0].url
                      : ""
                  }
                  width={"150"}
                  height={"100"}
                  alt="juju Designz logo"
                  style={{ borderRadius: 20 }}
                />
              </div>
            ) : null}

            {WorkDoneList[1] && WorkDoneList[1].url ? (
              <div>
                <img
                  src={
                    WorkDoneList[1] && WorkDoneList[1].url
                      ? WorkDoneList[1].url
                      : ""
                  }
                  width={"150"}
                  height={"100"}
                  alt="juju Designz logo"
                  style={{ borderRadius: 20 }}
                />
              </div>
            ) : null}

            <a>
              <div
                style={{
                  height: 100,
                  width: 150,
                  borderRadius: 20,
                  borderColor: colors.dark_blue,
                  borderWidth: 2,
                  cursor: "pointer",
                  backgroundImage: `url(${
                    WorkDoneList[2] && WorkDoneList[2].url
                      ? WorkDoneList[2].url
                      : ""
                  })`,
                }}
                onClick={() => navigate("/Work-Done")}
              >
                <div
                  style={{
                    backgroundColor: colors.dark_blue,
                    height: 150,
                    width: 150,
                    borderRadius: 20,
                    opacity: 0.5,
                    borderColor: colors.dark_blue,
                    borderWidth: 2,
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      // fontWeight: "bold",
                      textAlign: "center",
                      paddingTop: 40,
                      fontSize: 14,
                    }}
                  >
                    Explore More {">"}
                  </p>
                </div>
              </div>
            </a>
          </Stack>
        </Grid>
      </Grid>
    </section>
  );
}

export default SectionSlide;
