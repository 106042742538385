import React from "react";
import { Grid } from "@mui/material";
import { useStyles } from "../pages/Home/HomeStyles";
import colors from "../themes/colors";
import ScrollAnimation from "react-animate-on-scroll";
import TextTransition, { presets } from "react-text-transition";
import { motion } from "framer-motion";
import { useSpring, animated, config } from "react-spring";
import Typist from "react-typist";
import { SlideImage } from "../constant";

const dashboardBg = SlideImage.dashboardBg;
const serviceBg = SlideImage.serviceBg;
const planBg = SlideImage.planBg;
const aboutBg = SlideImage.aboutBg;
const giftBg = SlideImage.giftBg;
const blogBg = SlideImage.blogBg;

const TEXTS = [`| C R E A T I V E `, `| G R A P H I C S`];

const TEXTS2 = [
  `| WE ARE BEST AT SALEM 》》》`,
  `| CONTACT US TO BOOK ORDERS 》》》`,
];

const TEXTS3 = [
  `➠ C R E A T I V E `,
  `➠ D E S I G N     `,
  `➠ M O C K I N G   `,
  `➠ G R A P H I C S `,
];

type DashboardSlideProps = {
  title: string;
  description: string;
  color: string;
  img?: string;
};

export default function DashboardSlide({
  title,
  description,
  color,
  img,
}: DashboardSlideProps) {
  let titleSplitValue = title.indexOf(" ");
  const classes = useStyles();
  const [titleSplit] = React.useState({
    title1: title.substring(0, titleSplitValue),
    title2: title.substring(titleSplitValue + 1),
  });
  const [flip, set] = React.useState(false);

  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      6000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  const textChange = useSpring({
    to: { opacity: 0 },
    from: { opacity: 1 },
    reset: true,
    reverse: flip,
    delay: 2000,
    config: config.molasses,
    onRest: () => set(!flip),
  });

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.bg_image}
        style={{
          backgroundImage: `url(${
            "dashboardBg" === img
              ? dashboardBg
              : "serviceBg" === img
              ? serviceBg
              : "planBg" === img
              ? planBg
              : "aboutBg" === img
              ? aboutBg
              : "giftBg" === img
              ? giftBg
              : "blogBg" === img
              ? blogBg
              : ""
          })`,
        }}
      >
        <div className={classes.animation_background}></div>
        <Grid item p={0} m={0}>
          <Grid display={{ sm: "block", xs: "none" }}>
            <div
              style={{
                position: "fixed",
                right: "0vh",
                bottom: "60vh",
                // width: 200,
                display: "flex",
                paddingLeft: 20,
              }}
            >
              <div
                style={{
                  color: "white",
                  transform: "rotate(-90deg)",
                  fontSize: 12,
                  letterSpacing: 3,
                  // ...textChange,
                }}
              >
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    repeat: Infinity,
                    duration: 2,
                    repeatType: "reverse",
                  }}
                  style={{ color: "white", width: 200, paddingTop: 60 }}
                >
                  Design for Digital
                </motion.p>
              </div>
            </div>
          </Grid>

          <div>
            <ScrollAnimation animateIn="fadeIn">
              <p
                className={
                  color === "yellow" ? classes.title : classes.titleSplit2
                }
                style={{
                  padding: 5,
                  textAlign: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  borderRadius: 10,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                <Typist cursor={{ blink: true, element: "" }}>
                  {titleSplit.title1}{" "}
                  <span className={classes.titleSplit}>
                    {titleSplit.title2}
                  </span>
                </Typist>
              </p>
            </ScrollAnimation>
          </div>
          <p className={classes.description} style={{ paddingTop: 20 }}>
            {description}
          </p>
          <div
            style={{
              textAlign: "center",
              position: "absolute",
              bottom: 40,
              left: "49%",
            }}
          >
            <div className="indicator"></div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
