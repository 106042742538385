import { Route, Routes } from "react-router-dom";
import FooterComponent from "../components/FooterComponent";
import AboutComponent from "../pages/About/AboutComponent";
import Auth from "../pages/Auth/Auth";
import SignInComponent from "../pages/Auth/SignInComponent";
import SignUpComponent from "../pages/Auth/SignUpComponent";
import BlogComponent from "../pages/Blog/BlogComponent";
import BlogDetail from "../pages/Blog/BlogDetail";
import ContactComponent from "../pages/Contact/ContactComponent";
import HomeComponent from "../pages/Home/HomeComponent";
import PrivacyPolicy from "../pages/Info/PrivacyPolicy";
import TermsAndConditions from "../pages/Info/TermsAndConditions";
import PlanComponent from "../pages/Plan/PlanComponent";
import ServiceComponent from "../pages/Service/ServiceComponent";
import StoreComponent from "../pages/Store/StoreComponent";
import StoreDetailsComponent from "../pages/Store/StoreDetailsComponent";
import WorkComponent from "../pages/Work/WorkComponent";
import ScrollToTop from "../utils/ScrollToTop";

export default function RouterComponent() {
  return (
    <>
      <Routes>
        {/* <Route path="" element={<SignInComponent />} /> */}
        {/* <ScrollToTop /> */}
        <Route path="" element={<HomeComponent />} />
        <Route path="/About" element={<AboutComponent />} />
        <Route path="/Client-List" element={<AboutComponent />} />
        <Route path="/Services" element={<ServiceComponent />} />
        <Route path="/Work-Done" element={<ServiceComponent />} />
        <Route path="/Works" element={<WorkComponent />} />
        <Route path="/Plans" element={<PlanComponent />} />
        <Route path="/Contact" element={<ContactComponent />} />
        <Route path="/Gift-Store" element={<StoreComponent />} />
        <Route path="/Gift-Store-Details" element={<StoreDetailsComponent />} />
        <Route path="/Blog" element={<BlogComponent />} />
        <Route path="/Blog/Blog-details" element={<BlogDetail />} />
        <Route path="/Auth" element={<Auth />} />
        <Route path="/Sign-in" element={<SignInComponent />} />
        <Route path="/Sign-up" element={<SignUpComponent />} />
        <Route path="/Terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/Privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      <FooterComponent />
    </>
  );
}
