let colors = {
  yellow: "#ffc20f",
  dark_yellow: "#fba316",
  green: "#66d61e",
  dark_green: "#57b21d",
  dark_blue: "#272b36",
  gray_txt: "#CBCFD5",
  white: "#ffff",
  light_gray: "#6B6D71",
  glass:"#4B5056"
};

export default colors;
