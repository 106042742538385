import { Grid, Avatar, Button } from "@mui/material";
import { AnimationWrapper } from "react-hover-animation";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
export default function AvatarComponent({
  ClientList,
  from,
}: {
  ClientList?: any[];
  from?: string;
}) {
  let navigate = useNavigate();
  return (
    <>
      <Grid
        container
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        // p={4}
        // m={4}
        // pt={4}
      >
        {ClientList &&
          ClientList.slice(0, 4).map((item) => {
            return (
              <Grid item key={item.id} xs={5} sm={2} pt={4}>
                <motion.div whileHover={{ scale: 1.1 }}>
                  <Avatar
                    onClick={() => {
                      if (from === "home") {
                        navigate("Client-List");
                      }
                    }}
                    alt="juju Designz client"
                    variant="square"
                    src={item.url}
                    sx={{ width: 120, height: 120 }}
                  />
                </motion.div>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
}
