import { createTheme } from "@mui/material/styles";
import colors from "./colors";

export const theme = createTheme({
  palette: {
    // primary: {
    //   light: colors.yellow,
    //   main: colors.dark_yellow,
    // },
    // secondary: {
    //   light: colors.green,
    //   main: colors.dark_green,
    //   contrastText: "#ffcc00",
    // },
    warning: {
      light: colors.yellow,
      main: colors.dark_yellow,
    },
    info: {
      light: colors.green,
      main: colors.dark_green,
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});
