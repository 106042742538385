export class LocalStorage {
  static store(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }
  static get(key: string) {
    const response = localStorage.getItem(key);
    if (response) {
      return JSON.parse(response);
    } else {
      return null;
    }
  }
}
