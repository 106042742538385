import { Button, Grid, Avatar, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ColorLine from "../../components/ColorLine";
import colors from "../../themes/colors";
import { useStyles } from "./AuthStyle";
import { motion } from "framer-motion";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: colors.green,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: colors.green,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: colors.green,
      borderRadius: 7,
      borderWidth: 1,
    },
    "&:hover fieldset": {
      borderColor: colors.green,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.green,
    },
  },
});

type signInProps = {
  shift?: () => void;
};

function SignInComponent({ shift }: signInProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const SignIn = () => {
    if (shift) {
      shift();
    }
    if (email === "admin@example.com" && password === "admin") {
      // navigate("/Home");
    } else {
      alert("Email or Password Incorrect !");
    }
  };
  return (
    <>
      <motion.section animate={{ x: [-500, 0] }}>
        <Grid container direction="row">
          <Grid
            container
            sm={5}
            xs={12}
            direction="column"
            justifyContent={"space-between"}
            alignItems="center"
            style={{
              backgroundColor: colors.dark_blue,
              height: "99vh",
            }}
            order={{ xs: 3, sm: 2 }}
          >
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "40vh",
                marginTop: 120,
              }}
            >
              <Avatar
                alt="juju Designz"
                src={require("../../assets/RoundIcons/Icons_User.png")}
                sx={{ width: 60, height: 60 }}
              />
              <p className={classes.sectionSubTitle}>Hi Friend's</p>

              <p
                className={classes.sectionDes}
                style={{ textAlign: "center", width: "80%" }}
              >
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi
                molestias dignissimos a, consectetur dolores mollitia explicabo
                quod natus dolorem obcaecati iusto praesentium eos delectus?
                Officiis modi illum
              </p>
              <Button
                variant="contained"
                style={{
                  background:
                    "linear-gradient(90deg, rgba(102,214,30,1) 0%, rgba(87,178,29,1) 53%)",
                  marginTop: 30,
                  margin: 15,
                  textTransform: "capitalize",
                  width: "50%",
                  // borderRadius: 12,
                }}
                onClick={() => {
                  if (shift) {
                    shift();
                  }
                }}
              >
                Sign Up
              </Button>
            </Grid>
            <Grid
              container
              item
              direction={"column"}
              justifyContent={"flex-end"}
              alignItems={"flex-end"}

              // style={{
              //   textAlign: "right",
              // }}
            >
              <img
                src={require("../../assets/JuJu_Logo-03.png")}
                alt="juju Designz"
                style={{
                  opacity: 0.2,
                  width: "50%",
                  height: "100%",
                  margin: 0,
                  padding: 0,
                  position: "relative",
                  bottom: 0,
                }}
              />
            </Grid>
          </Grid>
          <Grid sm={7} xs={12} item p={5} pt={10} order={{ xs: 0, sm: 3 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <Avatar
                alt="juju Designz"
                src={require("../../assets/RoundIcons/Icons_User.png")}
                sx={{ width: 100, height: 100 }}
              /> */}
              <p
                style={{
                  textAlign: "center",
                  margin: 2,
                  fontSize: 30,
                  fontWeight: "bold",
                  paddingBottom: 60,
                  padding: 15,
                  paddingTop: 50,
                }}
              >
                Sign in to JuJu
              </p>

              <CssTextField
                size="small"
                style={{ marginTop: 10, width: 250 }}
                label="Email Id"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <br />
              <CssTextField
                size="small"
                style={{ marginTop: 3, width: 250, borderColor: "red" }}
                label="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <br />
              <Button
                // onClick={() => SignIn()}
                variant="contained"
                style={{
                  background:
                    "linear-gradient(90deg, rgba(102,214,30,1) 0%, rgba(87,178,29,1) 53%)",
                  marginTop: 10,
                  width: 250,
                  textTransform: "capitalize",
                }}
              >
                Sign In
              </Button>
              <p style={{ paddingTop: 20, fontSize: 11 }}>Forget Password ?</p>
            </div>
          </Grid>
        </Grid>
      </motion.section>
      <ColorLine />
    </>
  );
}

export default SignInComponent;
