import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import colors from "../themes/colors";

export const useFooterStyles = makeStyles((theme?: Theme) => ({
  footerTitle: {
    color: colors.white,
    fontSize: 22,

    paddingBottom: 25,
  },
  footerSubTitle: {
    color: colors.white,
    paddingTop: 10,
    cursor: "pointer",
    textTransform: "capitalize",

  },
  footerbg: {
    backgroundColor: colors.dark_blue,
  },
  footerInput: {
    "& .MuiInputBase-input.Mui-focused": {
      borderColor: "red",
    },
  },
}));
